<template>
  <UiPage
    :title="$t('app.page.advertising-campaigns')"
    :titleTemplate="$t('app.page.advertising-campaigns')"
    class="content-padding-mobile"
  >
    <template #top>
      <BackButton />
    </template>

    <template #content>
      <GeneralList />
    </template>
  </UiPage>
</template>

<script>
import UiPage from '@/ui/Page.vue'
import BackButton from '@/ui/buttons/BackButton.vue'
import GeneralList from '@/modules/profile/advertising-campaigns/GeneralList.vue'

export default {
  components: {
    UiPage,
    BackButton,
    GeneralList
  }
}
</script>
